<template>
    <!--传递给父组件内容， 父组件用props接收-->
    <div class="quiz">
        <div class="quiz_body">
            <p class="quiz_title" v-html="`${itemNum}. ${nl2br(itemDetail[itemNum-1].question)}`"></p>
            <br>
<!--          <img :src="itemDetail[itemNum - 1].img" alt="">-->
<!--          <img class="quiz_img" src="../assets/image/p1.jpg" alt="">-->
          <img class="quiz_img" v-if="itemDetail[itemNum-1].img" :src="require('../assets/image/'+itemDetail[itemNum - 1].img+'.jpg')" alt="">

            <div v-model="itemDetail[itemNum-1].userChoose">
                <ul v-for="(item, index) in itemDetail[itemNum-1].options">
                    <li class="item_li">
                        <label>
                            <input :type="itemDetail[itemNum-1].type"
                                   v-model="chosenResult"
                                   :value="item.value"
                                   :disabled="isDisabled"/>

                            <span class="item_value">{{ item.value }}.</span>
<!--                            <img class="img" v-if="item.type === 'image'" :src="item.options">-->
<!--                            <span class="item_value" v-else v-html="item.options"></span>-->
                            <span class="item_value item_value_br" v-html="item.option"></span>
                        </label>
                    </li>
                </ul>

                <div class="item_value" v-show="itemDetail[itemNum-1].remark && isButton">{{itemDetail[itemNum-1].remark}}</div>
            </div>
            <!--            <div v-show="showAnswer">-->
            <!--                <p class="true_quiz">正确答案是：</p>-->
            <!--            </div>-->
            <button class="button" @click="showAns" v-show="!isButton">确认</button>
            <div class="button_box" v-show="isButton">
                <!--最后一题显示提交按钮-->
                <div v-if="itemNum === itemDetail.length ">
                    <button class="button" type="success" @click="submitFun">完成</button>
                </div>
                <div v-else>
                    <button class="button" type="success" @click="nextFun">下一题</button>
                </div>
                <!--            <div v-if="itemNum>1">-->
                <!--              <button class="button" type="success" @click="beforeFun">上一页</button>-->
                <!--            </div>-->
            </div>

        </div>

        <div class="quiz_alert" v-show="showAnswer">
            <div class="quiz_alert_box">
                <span class="alert_close" @click="toClose">x</span>
                <div class="alert_img" :class="{active:!isCorrect}">
                    <img v-if="isCorrect" src="../assets/image/icon_correct.png" alt="">
                    <img v-if="!isCorrect" src="../assets/image/icon_wrong.png" alt="">

                </div>
                <p v-if="isCorrect" class="alert_msg">回答正确</p>
                <p v-if="!isCorrect" class="alert_msg">回答错误<br>正确答案是：{{
                        Array.isArray(itemDetail[itemNum - 1].answer) ? itemDetail[itemNum - 1].answer.join(',') : itemDetail[itemNum - 1].answer
                    }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import {mapState, mapActions, mapGetters} from 'vuex'
import axios from "axios";
export default {
    name: 'AnswerContainer',
    data() {
        return {
            //chosenResult: null,
            selectVal: null,
            showAnswer: false,
            isClick: true,
            //isButton:false,
            currentSelected: false,
            isCorrect: false,
            notifyTimeout: null,
            chosenResult: []
        }
    },

    props: ['fatherData'],

    computed: {
        ...mapState([
            'itemNum', 'itemDetail',
        ]),
        /*
        chosenResult: {
            get() {
                // console.log(this.$store.state.itemNum - 1, JSON.stringify(this.$store.getters.chosenResult));
                console.log('comp_val',this.getChosenResult());
                return this.getChosenResult();
            },
            set(val) {
                this.setAnswer(val);
            }
        },*/
        isDisabled() {
            return this.currentSelected || this.$store.getters.isSubmit;
        },
        isButton() {
            return this.isDisabled;
        },
        // isCorrect(){
        //   console.log('111',this.$store.getters.isCorrect);
        //   return this.$store.getters.isCorrect;
        // }
    },
    mounted() {
        // this.isDisabled = this.$store.getters.chosenResult.length>0
    },
    filters: {

    },
    watch: {},
    methods: {
        ...mapActions([
            'nextQuestion', 'beforeQuestion', 'initData', 'setAnswer', 'getChosenResult', 'isCorrectChosen'
        ]),
        nl2br(value) {
            if (Object.prototype.toString.call(value) !== "[object String]") {
                return '';
            } else {
                value = value.replace(new RegExp('\\\\n','g'), '<br />');
                value = value.replace(new RegExp(/\r?\n/, 'g'), '<br />');

                return value;
            }
        },
        showAns() {
            //没有选校验
            console.log('showAns', this.chosenResult);
            if (!this.chosenResult || this.chosenResult.length==0) {
                alert("没有选择答案");
            } else {
                this.setAnswer(this.chosenResult);
                //this.isButton = true
                console.log('showAns', this.$store.getters.isCorrect);
                this.isCorrect = this.$store.getters.isCorrect;
                console.log(this.isCorrect);
                this.showAnswer = true
                this.currentSelected = true;

                this.notifyTimeout = setTimeout(() => {
                    this.showAnswer = false
                }, 2000)

            }
        },
        toClose() {
            this.showAnswer = false
            if (this.notifyTimeout != null) {
                clearTimeout(this.notifyTimeout);
            }
        },
        submitFun() {
            if (!this.chosenResult) {
                alert("没有选择答案");
            } else {
                if (this.isClick) {
                    this.isClick = false
                    this.showAnswer = true
                    this.nextQuestion();
                    this.$router.replace({name: 'score'});
                    this.showAnswer = false
                    this.isClick = true
                }
            }
        },
        nextFun() {
            //没有选校验
            if (!this.chosenResult) {
                console.log('nextFun', this.itemDetail[this.itemNum - 1]);
                alert("没有选择答案");
            } else {
                if (this.isClick) {
                    this.isClick = false
                    this.showAnswer = false
                    // this.isButton = false
                    this.chosenResult = [];
                    this.nextQuestion();
                    this.isClick = true
                    this.currentSelected = false;
                }
            }

        },
        beforeFun() {
            //this.isButton = true
            this.beforeQuestion();
        },
    }
}
</script>


<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.quiz
    width 100%
    padding 20px 40px
    box-sizing border-box

    .quiz_body
        .quiz_title
            font-size 32px
        .quiz_img
          width 100%
        .item_li
          padding-bottom 50px
        .img
            padding-left 10%
            width 70%

        .item_value
            font-size 30px
            padding 20px 0
            //display inline-block
        .true_quiz
            font-size 32px

    .button
        buttonSet()
        margin 120px 15%
        width 70%

    .quiz_alert
        position fixed
        background-color rgba(0, 0, 0, 0.6)
        top 0
        right 0
        bottom 0
        left 0
        display flex
        flex-direction column
        align-items center
        justify-content center

        .quiz_alert_box
            width 600px
            height 400px
            background-color white
            border-radius 60px
            display flex
            flex-direction column
            align-items center
            justify-content space-around
            padding 20px 0
            box-sizing border-box
            position: relative;

            .alert_close
                position absolute
                top 0
                right 0
                font-size 36px
                width 80px
                height 80px
                text-align center

            .alert_img
                width 150px
                height 150px
                border-radius 50%
                background-color green

                img
                    width 100%
                    height 100%

            .active
                background-color #B91C1C

            .alert_msg
                font-size 32px
                text-align center
                line-height 50px
</style>
