<template>
    <!--登录-->
    <div class="login">
        <div class="login_content">
            <span class="login_content_title">员工登录</span>
            <div class="login_content_input">
                <input type="text" v-model="staff.userId" placeholder="请输入7位员工号(i+6位数字)">
                <span class="login_content_input_warn"
                      :class="valUseridFormat(staff.userId)?'success':'error'">
          {{ this.staff.userId.length === 0 ? '*' : (valUseridFormat(staff.userId) ? '√' : '请检查格式') }}
        </span>
            </div>
            <div class="login_content_input">
                <input type="email" v-model="staff.email" placeholder="请输入SAP公司邮箱">
                <span class="login_content_input_warn"
                      :class="valEmailFormat(staff.email)?'success':'error'">
          {{ this.staff.email.length === 0 ? '*' : (valEmailFormat(staff.email) ? '√' : '请检查格式') }}
        </span>
            </div>
            <button class="login_content_button" @click="login">登录</button>
        </div>

        <Error v-if="isShow" @close="isShow=false" :message="message"></Error>

    </div>
</template>

<script>
import Error from '../components/error'
import {mapActions} from 'vuex'
import {valUserid, valEmail} from "./valid";

export default {
    name: "login",
    components: {
        Error
    },
    data() {
        return {
            staff: {
                userId: '',
                email: '',
            },
            isShow: false,
            message: ''
        }
    },
    methods: {
        ...mapActions({
            doLogin: 'passport/login',
            staffUseridEmail: 'register/staffUseridEmail',
        }),
        login() {
            this.doLogin(this.staff).then(() => {
                this.staffUseridEmail(this.staff)
                this.$router.replace({name: 'index'})
            }).catch((err) => {
                this.isShow = true
                console.log(err);
                this.message = err.message
            });
        },

        //输入格式判断
        valUseridFormat(input) {
            return valUserid(input);
        },
        valEmailFormat(input) {
            return valEmail(input);
        },
    }
}

</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
//@import '../../styles/mixins.styl'
@import '../assets/mixins.styl'

.error
    color $error

.success
    color green

.login
    width 750px
    height 1333px
    background no-repeat url("../assets/image/login_page_bac0.jpg")
    background-size 750px 1333px
    //background-color:$theme;
    //登录框

    .login_content
        width 650px
        min-height 462px
        background-color white
        box-shadow 0 17px 35px 0 rgba(0, 41, 30, 0.08)
        border-radius 60px
        position absolute
        top 800px
        left 0
        right 0
        margin auto
        z-index 99
        display flex
        flex-direction column
        align-items center

        .login_content_title
            titleSet()
            margin 40px 0 44px

        .login_content_input > input
            width 599px
            inputSet()
            margin-bottom 12px

        input::placeholder
            inputPlaceholderSet()

        .login_content_input_warn
            font-size 30px
            line-height 86px
            position absolute
            right 60px

        //color $error

        .login_content_button
            buttonSet()
            //primaryBtn()
            margin 17px 0 33px
            color white
</style>
