<template>
    <div class="index">
      <div class="index_inner">
        <div class="index_banner">
          <img class="img" src="../assets/image/banner.jpg" alt="">
        </div>
        <router-view/>
      </div>

      <div class="index_body" v-show="!isQuiz">
        <div>
          <p class="index_body_info">
              今年的1月29日是我国的传统节日“春节”， SAP北分工会特挑选了与“新春”有关的知识问答，欢迎大家踊跃参加，给会员们提前拜年啦！
          </p>
            <p class="index_body_info">
                截止日期：即日起 到1月15日（星期三）18:00
            </p>

<!--          <div class="index_body_img">-->
<!--            <img src="../assets/image/gift.jpg" alt="">-->
<!--&lt;!&ndash;            <span class="orspan">或</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <img src="../assets/image/gift.png" alt="">&ndash;&gt;-->
<!--            <span>-->
<!--                花王洗洁精+衣物柔顺剂组合（香味随机）-->
<!--                <br>*图片仅供示意参考，具体以实物为准。</span>-->
<!--          </div>-->
        </div>

          <div class="index_body_button_box">
              <button class="index_button online" @click="regist()">开始答题</button>
          </div>

      </div>


      <div v-if="questionLoaded">
          <div v-show="isQuiz">
              <Quiz></Quiz>
          </div>
      </div>
        <!--      <Error v-if="isShow" @close="isShow=false" :message="message"></Error>-->
    </div>
</template>

<script>
import Quiz from "../components/quiz"
import {mapActions} from "vuex";

export default {
    name: "login",
    components: {
        Quiz,
    },
    data() {
        return {
            isQuiz: false,
            questionLoaded: false,
        }
    },
    mounted() {
        this.loadQuestion().then(() => {
            this.questionLoaded = true;
        })
    },
    methods: {
        ...mapActions({
            'loadQuestion': 'initData'
        }),
        regist() {
            this.isQuiz = true
        }
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'

.index
  width 100%
  height 100%
  position relative
  .index_inner
    width 750px
    height 100%
    .index_banner
      width 100%
      .img
        width 100%;
        box-shadow  10px 10px 15px rgba(0,0,0,0.2);
  .index_body
    width 750px
    height 80vh
    position absolute
    top 300px
    display flex
    flex-direction column
    align-items center
    //justify-content space-around
    .index_body_info
      text-indent 2em
      padding 0 55px
      font-size 32px
      //font-family  "Arial","Microsoft YaHei","黑体","宋体",sans-serif;
      fontFamily()
      font-weight 400
      color #000
      line-height 40px
      box-sizing border-box
    .index_body_img
      width 100%
      display flex
      flex-direction column
      align-items  center
      img
        width 460px
        padding 10px 0
      span
        padding 10px 0
      .orspan
        font-size 40px
        font-weight bold
        //color red
        padding 20px  0

    .index_body_info_more
      padding-bottom 60px
    .index_body_button_box
      //width 100%
      padding 31px 75px
      .index_button
        buttonSet()
        //primaryBtn()




</style>
